import { useState } from 'react'
import './product.css'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useInView} from 'react-intersection-observer';



function Productcard(props){

    
    const data = props;
    const isDiscounted = data.product.discount === "true";
    const discountedPrice = data.product.discountPrice;
    const color = data.product.color;
    const img = data.product.image;
    const price = data.product.price;
    const name = data.product.name;
    const productId = data.product.prodID;
    const multipleImages = (data.product.multipleImages || []).filter(x => x !== data.product.image).slice(0, 3);
    const extraImages = multipleImages;

    const { ref, inView } = useInView({
        threshold: 0.2, // Adjust threshold as needed
    });
   
    const [hasAnimated, setHasAnimated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(inView && !hasAnimated)
        {
            setHasAnimated(true);
        }
    }, [inView])

    const paddName = (name) => {
        return name.split(" ").join("-");
    };


    const navToProduct = () => {
        const paddedName = paddName(name);
        navigate(`/product/${paddedName}`, { state: { prodID: productId } });
    };
    

  


    return(
      
        <div  style={{maxWidth: "100%", height: "auto", borderRadius: "10px"}} type="button" onClick={navToProduct}>
            <div style={{maxWidth: "100%", height: "auto", borderRadius: "10px", position: "relative"}}>
                
                <img 
                     ref={ref}
                     className={`img-fluid ${hasAnimated ? 'product-card-anim' : 'hidden-product'}`}
                     src={img}
                     style={{ borderRadius: '10px', display: 'block' }}
                     alt="Product Image">
                </img>
                
                <div style={{maxWidth: "100%", height: "auto", display: "flex", justifyContent: "center", gap: "8px"}}>
                {multipleImages.map((imageUrl, index) => (
                    <img 
                    className={`img-fluid ${hasAnimated ? 'product-card-anim' : 'hidden-product'}`}
                    style={{width: '25%', borderRadius: "15px", background: "#e1dede", boxShadow: "#00000036 0px 0px 4px 2px"}} key={index} src={imageUrl}>
                    </img>
                ))}
                </div>

                <div className='overlay overlaytwo'> 
                    <div className='to-product text-center'>
                        <b className='text-center'>Till Produkt</b>
                    </div>
                </div>

            </div>
            
            <div className='d-flex flex-column justify-content-center align-items-center py-1'>
                <h3 
                className={`text-center ${hasAnimated ? 'text-roll' : 'hidden-product'}`}>
                    {name}
                </h3>
                
                {!isDiscounted ? (
                    <>
                        {price !== undefined ? <p className={`text-center ${hasAnimated ? 'text-roll' : 'hidden-product'}`} style={{margin: 0}}>{price} kr</p> : null}
                    </>

                ) : (
                    <>
                        {price !== undefined ? <p className={`text-center ${hasAnimated ? 'text-roll' : 'hidden-product'}`} style={{margin: 0, textDecoration: "line-through"}}>{price} kr</p> : null}
                        {price !== undefined ? <p className={`text-center ${hasAnimated ? 'text-roll' : 'hidden-product'}`} style={{margin: 0, color: "red"}}>{discountedPrice} kr</p> : null}
                        
                    </>

                )}


            </div>
        
        </div>
    
    );

}

export default Productcard;