import { useEffect, useState } from 'react';
import Productcard from '../components/product';
import "./allproducts.css";


const Allproductspage = () =>  
{
    const [products, setProducts] =  useState([])
    const [prevPress, setPrevPress] = useState("");
    const [runFadeAnim, setAnim] = useState(false);
    const [originalProducts, setOriginalProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('');
    const [apiLoaded, setApiLoaded] = useState(false);

    const initSort =(prodctsarray) => {
        prodctsarray.sort((a, b)=>b.sortBy - a.sortBy);
        return prodctsarray;

    }


    useEffect(() => 
    {

        const fetchAllProducts = async() => 
        {
            const response = await fetch('https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/fetch-product-page');
            const data = await response.json();
            const prodsSorted = initSort(data.data);
            setProducts(prodsSorted);
            setOriginalProducts(prodsSorted);
    
        }
        fetchAllProducts();


    },[])

    useEffect(() => {

        if(products.length > 0 && originalProducts.length > 0)
        {
            setApiLoaded(true);
        }
        
    },[products, originalProducts])


    const rerunAnimation = () => {
        setAnim(false);
        setTimeout(() => setAnim(true), 10);
    }


    const sortByLowestPrice = () => {
        const sortedProducts = [...products].sort((a, b) => {
            // Check if each product is discounted
            const priceA = a.discount === "true" ? a.discountPrice : a.price;
            const priceB = b.discount === "true" ? b.discountPrice : b.price;
            return priceA - priceB;
        });
        setProducts(sortedProducts);
        rerunAnimation();
    };
    
    const sortByHighestPrice = () => {
        const sortedProducts = [...products].sort((a, b) => {
            // Check if each product is discounted
            const priceA = a.discount === "true" ? a.discountPrice : a.price;
            const priceB = b.discount === "true" ? b.discountPrice : b.price;
            return priceB - priceA;
        });
        setProducts(sortedProducts);
        rerunAnimation();
    };


    const sortByNameAZ = () => {
        const sortedProducts = [...products].sort((a, b) => a.name.localeCompare(b.name));
        setProducts(sortedProducts);
        rerunAnimation();
    };
    
      // Function to handle sorting by name Z-A
      const sortByNameZA = () => {
        const sortedProducts = [...products].sort((a, b) => b.name.localeCompare(a.name));
        setProducts(sortedProducts);    
        rerunAnimation();
    };

    const sortByPopularity = () => 
    {
        const sortedProducts = [...products].sort((a, b) => parseInt(a.rating) < parseInt(b.rating));
        setProducts(sortedProducts);
        rerunAnimation();
    };

    const sortBtnPearch = () => {
        let sortedProducts = products.filter(x => x.art === "perch"); 
        if (prevPress === "pike") {
            sortedProducts = originalProducts.filter(x => x.art === "perch");
        } else {
            sortedProducts = products.filter(x => x.art === "perch");
        }
        setProducts(sortedProducts);
        setPrevPress("perch");
        rerunAnimation();
    }

    const sortBtnPike = () => {
        let sortedProducts = [];
        if (prevPress === "perch") {
            sortedProducts = originalProducts.filter(x => x.art === "pike");
        } else {
            sortedProducts = products.filter(x => x.art === "pike");
        }
        setProducts(sortedProducts);
        setPrevPress("pike");
        rerunAnimation();
    }

    const sortBtnAllProducs = () => {
        setProducts(originalProducts);
        rerunAnimation();
    }
    
    // Function to handle changes in the search input
    const handleSearchInputChange = (event) => 
    {
        const query = event.target.value;
        setSearchQuery(query);   
        if(query.trim() === '')
        {
            setProducts(originalProducts);
        }
        else
        {
            const filteredProducts = products.filter(product =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setProducts(filteredProducts);  
        }
    };

   


    return(
        <div className="container">

            {apiLoaded ? (
                <>

            <div className="row pt-md-5 pt-3  pb-2">
                <div className="col-4 d-md-flex d-none align-items-center">
                    <div className='d-flex w-100' style={{backgroundColor: "black", height: "2px"}}></div>
                </div>
                
                <div className="col-md-4 col-12 d-flex align-items-center justify-content-center">
                    <h2 className="fw-bolder">Alla produkter</h2> 
                </div>

                <div className="col-12 d-lg-none d-md-none d-flex  align-items-center">
                    <div className='d-flex w-100' style={{backgroundColor: "black", height: "2px"}}></div>
                </div>

                <div className="col-4 d-md-flex d-none align-items-center">
                    <div className='d-flex w-100' style={{backgroundColor: "black", height: "2px"}}></div>
                </div>

            
               
            </div>

            <div className='row pt-3' style={{backgroundColor: "#e2e2e2", borderRadius: "10px"}}>
                <div className='col-md-3 col-12'>
                    <div class="input-group mb-3">            
                        <input  value={searchQuery} onChange={handleSearchInputChange} type="text" class="form-control" placeholder="Sök Produkt"/>
                    </div>
                </div>
                
                <div className='col-md-3 col-12'>
                    <div class="dropdown">
                    <button class="btn btn-light d-flex w-100 align-items-center justify-content-between dropdown-toggle mb-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Sortera efter
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#" onClick={sortByPopularity}>Mest populär</a></li>
                        <li><a class="dropdown-item" href="#" onClick={sortByNameAZ}>Namn A-Ö</a></li>
                        <li><a class="dropdown-item" href="#" onClick={sortByNameZA}>Namn Ö-A</a></li>
                        <li><a class="dropdown-item" href="#" onClick={sortByHighestPrice}>Högsta pris</a></li>
                        <li><a class="dropdown-item" href="#" onClick={sortByLowestPrice}>Lägsta pris</a></li>
                    </ul>
                    </div>
                </div>

                <div className='col-md-3 col-12'>
                    <div class="dropdown">
                    <button class="btn btn-light d-flex w-lg-75 w-md-75 w-100 align-items-center justify-content-between dropdown-toggle mb-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Kampanjer
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#">Outlet</a></li>
                        <li><a class="dropdown-item" href="#">Superdeal</a></li>
                        
                    </ul>
                    </div>
                </div>

                <div className='col-12 pb-3'>
                    <div className='row gap-md-0 gap-2'>
                        <div className='col-md-4 col-12'>
                            <button className='btn btn-light w-100' onClick={sortBtnAllProducs}>Alla produkter</button>
                        </div>
                        
                        <div className='col-md-4 col-12'>
                            <button className='btn btn-light w-100' onClick={sortBtnPike}>Gädda</button>
                        </div>
                        <div className='col-md-4 col-12'>
                            <button className='btn btn-light w-100' onClick={sortBtnPearch}>Abborre</button>
                        </div>
                    
                    </div>
                </div>


                
            </div>
            
            <div className={`row pt-md-3 pt-0 ${runFadeAnim ? "fade-in-products" : ""}`}>
                {products.map((product) => (
                <div className={`col-lg-3 col-md-4 col-6 py-2`}>
                        <Productcard product={product}></Productcard>
                </div>
                ))}   
            </div>
        
            </>
             ) : (
                <div className="row justify-content-center">
                    <div className="col-12 d-flex justify-content-center">
                        <div className="d-flex justify-content-center align-items-center" style={{width: "100%", height: "40vw"}}>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>

                        </div>


                    </div>
                </div>

              )}
        
        
        
        
        </div>





    )





}

export default Allproductspage;