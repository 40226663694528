import "./adminpanel.css";
import { useEffect, useState } from "react";


function AdminProduct(props) {
    // Initialize state with original values
    const [name, setName] = useState(props.name || '');
    const [color, setColor] = useState(props.color || '');
    const [price, setPrice] = useState(props.price || '');
    const [discountPrice, setDiscountPrice] = useState(props.discountPrice || '');
    const [specWeight, setSpecWeight] = useState(props.specWeight || '');
    const [specLength, setSpecLength] = useState(props.specLength || '');
    const [inventoryBalance, setInventoryBalance] = useState(props.inventoryBalance || 0);
    const [sortBy, setSortBy] = useState(props.sortBy || '');
    const [sortByNews, setSortByNews] = useState(props.sortByNews || "");
    const [sortByDeals, setSortByDeals] = useState(props.sortByDeals || "");
    const [newslider, setNewslider] = useState(props.newslider === "true");
    const [dealslider, setDealslider] = useState(props.dealslider === "true");
    const [discount, setDiscount] = useState(props.discount === "true");
    const [productPage, setProductPage] = useState(props.productPage === "true");
    const [desc, setDesc] = useState(props.desc || '');
    const prodID = props.prodID;

    // Handle form submit
    const postChanges = (e) => {
      e.preventDefault();
      
      const request = {
        prodID: prodID,
      };
      let anyChange = false;
      let orgPriceChange = false;
      let orgDiscountPriceChange = false;
      let issueMsg = ``;
  
      // Compare current state values with original props
      if (name !== props.name) {
        anyChange = true;
        request.name = name;
        issueMsg += `\nDu byter namn från ${props.name} till ${name}`;
      }
      if (color !== props.color) {
        request.color = color;
        anyChange = true;
        issueMsg += `\nDu byter färg från ${props.color} till ${color}`;
      }
      if (price !== props.price) {
        request.price = price;
        orgPriceChange = true;
        anyChange = true;
        issueMsg += `\nDu byter pris från ${props.price} till ${price}`;
      }
      if (discountPrice !== props.discountPrice) {
        request.discountPrice = discountPrice;
        orgDiscountPriceChange = true;
        anyChange = true;
        issueMsg += `\nDu byter rabattpris från ${props.discountPrice} till ${discountPrice}`;
      }
      if (specWeight !== props.specWeight) {
        request.specWeight = specWeight;
        anyChange = true;
        issueMsg += `\nDu byter vikt från ${props.specWeight} till ${specWeight}`;
      }
      if (specLength !== props.specLength) {
        request.specLength = specLength;
        anyChange = true;
        issueMsg += `\nDu byter längd från ${props.specLength} till ${specLength}`;
      }
      
      if (inventoryBalance !== props.inventoryBalance) {
        request.inventoryBalance = inventoryBalance;
        anyChange = true;
        issueMsg += `\nDu byter lagersaldo från ${props.inventoryBalance} till ${inventoryBalance}`;
      }
      if (sortBy !== props.sortBy) {
        request.sortBy = sortBy;
        anyChange = true;
        issueMsg += `\nDu byter sorteringsvärde från ${props.sortBy} till ${sortBy}`;
      }
      if (sortByNews !== props.sortByNews) {
        request.sortByNews = sortByNews;
        anyChange = true;
        issueMsg += `\nDu byter sorteringsvärde från ${props.sortByNews} till ${sortByNews} för news slider`;
      }
      if (sortByDeals !== props.sortByDeals) {
        request.sortByDeals = sortByDeals;
        anyChange = true;
        issueMsg += `\nDu byter sorteringsvärde från ${props.sortByDeals} till ${sortByDeals} för deals slider`;
      }
      if (newslider !== (props.newslider === "true")) {
        newslider ? request.newslider = "true" : request.newslider = "false"; 
        anyChange = true;
        issueMsg += `\nDu ändrar News Slider värde.`;
      }
      if (dealslider !== (props.dealslider === "true")) {
        dealslider ? request.dealslider = "true" : request.dealslider = "false"; 
        anyChange = true;
        issueMsg += `\nDu ändrar Deals Slider värde.`;
      }
      if (discount !== (props.discount === "true")) {
        discount ? request.discount = "true" : request.discount = "false";
        if (discount) {
          if (!request?.discountPrice) { // No new discount price, fallback to old.
            request.discountPrice = props.discountPrice;
          }
        } else if (!request?.price) {
          request.price = props.price;
        }

        anyChange = true;
        issueMsg += `\nDu ändrar rabattstatus.`;
      }
      if (desc !== props.desc) {
        request.desc = desc;
        anyChange = true;
        issueMsg += `\nDu ändrar beskrivningen.`;
      }
      if (productPage !== (props.productPage === "true")) {
        productPage ? request.productPage = "true" : request.productPage = "false";
      }
      console.log(request);
      // If any change detected, show a confirmation dialog
      if (anyChange) {
        const confirm = window.confirm(issueMsg);
        if (confirm) {
        
          const postChangeReq = async(token) => {
              const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/change-product", {

              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,   
                  "Content-Type": "application/json",

              }, 
              body: JSON.stringify(request),
              });
              const data = await response.json();
            
              if (data?.status) {
                window.location.reload();
              }       
              
          }
          const token = localStorage.getItem("tlToken");
          if (token) {
            postChangeReq(token);  
          } else {
            localStorage.clear("tlToken");
            window.location.reload();
          }
        }
      } else {
        alert('Inga ändringar har gjorts.');
      }
    };

    const deletProduct = (e) => {
      e.preventDefault();
      const request = {
        prodID: prodID,
      };
      const issueMsg = `Du tar bort ${name} i färgen ${color} är du säkert?`;
      const confirm = window.confirm(issueMsg);
      if (confirm) {
        console.log("fetch")
        const postDelReq = async(token) => {
          const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/delete-product", {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`,   
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
          });
          const data = await response.json();
          if (data?.status) {
            window.location.reload();
          }

        }
        const token = localStorage.getItem("tlToken");
        if (token) {
          postDelReq(token);  
        } else {
          localStorage.clear("tlToken");
          window.location.reload();
        }
      } else {
        alert('Inga ändringar har gjorts.');
      }




    };
  


    return (
      <form onSubmit={postChanges} className="row mb-4" style={{ backgroundColor: "#bcbcc1", borderRadius: "6px", border: "1px solid #3f3737" }}>
        <div className="col-4 d-flex align-items-center">
          <img className="img-fluid" src={props.image} alt="Product" />
        </div>
        <div className="col-3 py-3">
          <label className="fw-bold">Namn</label>
          <input
            disabled
            name="name"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label className="fw-bold">Färg</label>
          <input
            disabled
            name="color"
            className="form-control"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <label className="fw-bold" type="number">Pris (Standard, ange med .00)</label>
          <input
            name="price"
            className="form-control"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <label className="fw-bold" type="number">Pris (Rabatt, ange med .00)</label>
          <input
            name="discountPrice"
            className="form-control"
            value={discountPrice}
            onChange={(e) => setDiscountPrice(e.target.value)}
          />
          <label className="fw-bold">Vikt</label>
          <input
            name="specWeight"
            className="form-control"
            value={specWeight}
            onChange={(e) => setSpecWeight(e.target.value)}
          />
          <label className="fw-bold">Längd</label>
          <input
            name="specLength"
            className="form-control"
            value={specLength}
            onChange={(e) => setSpecLength(e.target.value)}
          />
          <label className="fw-bold">Lagersaldo</label>
          <input
            name="inventoryBalance"
            className="form-control"
            type="number"
            value={inventoryBalance}
            onChange={(e) => setInventoryBalance(e.target.value)}
          />
          <label className="fw-bold">Sorteringsvärde (Standard)</label>
          <input
            name="sortBy"
            className="form-control"
            type="number"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="fw-bold pt-2 pe-3">News Slider</label>
            <input
              name="newslider"
              className="form-check-input mt-2"
              type="checkbox"
              checked={newslider}
              onChange={(e) => setNewslider(e.target.checked)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="fw-bold pt-2 pe-3">Deals Slider</label>
            <input
              name="dealslider"
              className="form-check-input mt-2"
              type="checkbox"
              checked={dealslider}
              onChange={(e) => setDealslider(e.target.checked)}
            />
          </div>
          <div>
            <label className="fw-bold pt-2 pe-3">Sätt på rabattpris</label>
            <input
              name="discount"
              className="form-check-input mt-2"
              type="checkbox"
              checked={discount}
              onChange={(e) => setDiscount(e.target.checked)}
            />
          </div>
          <div>
            <label className="fw-bold pt-2 pe-3">Sätt på produktsida</label>
            <input
              name="productPage"
              className="form-check-input mt-2"
              type="checkbox"
              checked={productPage}
              onChange={(e) => setProductPage(e.target.checked)}
            />
          </div>

  
          
          <label className="fw-bold pt-2 pe-3">Sorteringsvärde News Slider</label>
          <input 
            className="form-control" 
            type="number" 
            value={sortByNews}
            onChange={(e => setSortByNews(e.target.value))}
          />
          <label className="fw-bold pt-2 pe-3">Sorteringsvärde Deals Slider</label>
          <input 
            className="form-control" 
            type="number" 
            value={sortByDeals}
            onChange={(e => setSortByDeals(e.target.value))}
          />
          
        </div>
  
        <div className="col-5 py-3 d-flex flex-column justify-content-space-between">
          <div style={{ height: "85%" }}>
            <label className="fw-bold">Beskrivning</label>
            <textarea
              style={{ height: "90%" }}
              className="form-control"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          <div>
            <button className="btn btn-success w-100 mb-3" type="submit">Genomför ändring</button>
            <button className="btn btn-danger w-100" onClick={(e) => deletProduct(e)}>Släng produkt</button>
          </div>
        </div>
      </form>
  
    );
  }
  







function Adminpanel() {

    const [currentProducts, setCurrentProducts] = useState([]);
    const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview
    const [image, setImage] = useState(null); // State to hold the image file
    // Validerar token.
    useEffect(()=> {
        const onLoadValidation = async(token) => {
            const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/validate-token", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,   
                }, 
            });

            const data = await response.json();
            if (!data.status) {
                localStorage.clear("tlToken");
                window.location.reload();
            }

        };
        const token = localStorage.getItem("tlToken");
        if (token) {
            onLoadValidation(token);
            startExpTimer(3600000);
        } else {
            localStorage.clear("tlToken");
            window.location.reload();
        }

    }, []);

    useEffect(() => {
        const loadCurrentProducts = async() => {
            const response = await fetch('https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/fetch-all-products');
            const data = await response.json();
            setCurrentProducts(data.data || []);
        }
        loadCurrentProducts();
    }, []);

    // Loggar ut om token är gammal
    const startExpTimer = (time) => {
        setTimeout(() => {
            alert('Sessionen är slut. Du behöver logga in igen');
            localStorage.clear("tlToken");
            window.location.reload();
        }, time);
    };
    
    // Laddar upp produkt.
    const submitUpload = async(e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const price = e.target.price.value;
        const weight = e.target.weight.value;
        const length = e.target.length.value;
        const lager = e.target.lager.value;
        const color = e.target.color.value;
        const desc = e.target.desc.value;
        const artPerch = e.target.artPerch.checked;
        const artPike = e.target.artPike.checked;
        const dealslider = e.target.dealslider.checked;
        const newsslider = e.target.newsslider.checked;
        const art = artPerch ? "perch" : "" || artPike ? "pike" : "";
        
        
        const token = localStorage.getItem("tlToken");
        if (!token) {
            localStorage.clear("tlToken");
            window.alert("Misslyckad uppladdning! Testa logga in igen");
            window.location.reload();
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('price', price);
        formData.append('weight', weight);
        formData.append('length', length);
        formData.append('lager', lager);
        formData.append('color', color);
        formData.append('desc', desc);
        formData.append('dealslider', dealslider);
        formData.append('newsslider', newsslider);
        formData.append('art', art);
        if (image) {
            formData.append('image', image);
        }
        
      
        const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/admin-upload-product", {   
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        const result = await response.json();
        if (result.status) 
        {
            window.alert("Produkten laddades upp!");
            setImage(null);
            setImagePreview(null);
            e.target.name.value = "";
            e.target.price.value = "";
            e.target.weight.value = "";
            e.target.length.value = "";
            e.target.lager.value = "";
            e.target.color.value = "";
            e.target.image.value = "";
            e.target.desc.value = "";
            e.target.dealslider.checked = false;
            e.target.newsslider.checked = false;
            e.target.artPerch.checked = false;
            e.target.artPike.checked = false;

        } else {
            const reason = result.reason;
            localStorage.clear("tlToken");
            window.alert(`Misslyckad uppladdning! Anledning: ${reason}. Kontakta IT-AVD <3`);
            window.location.reload();
        }
        
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
          // Check file type
          if (file.type === 'image/png' || file.type === 'image/jpeg') {
            setImage(file);
            const reader = new FileReader();
        

            reader.onload = (e) => {
              setImagePreview(e.target.result); // Set image preview URL
            };
            
            reader.readAsDataURL(file); // Read the file as a Data URL
          } else {
            alert('Please select a PNG or JPG image.');
            event.target.value = ''; // Clear the file input
          }
        }
    };








    return(
        <div className="container pb-5">
            <div className="row">
                <h1 className="admin-header-text text-center pt-5">Välkommen till TLfiske adminpanel</h1>            
            </div>
            <div className="row d-flex justify-content-center pt-5">
                <div className="col-5" style={{backgroundColor: "white", borderRadius: "8px"}}>
                    <h5 className="text-center pt-3">Ladda upp ny produkt</h5>
                    
                    <form onSubmit={e => submitUpload(e)}>
                        <label>Produktnamn</label>
                        <input id="name" className="form-control" type="text"  required/>

                        <label>Pris (standard)</label>
                        <input id="price" className="form-control" type="number"  required/>

                        <label>Bild (1080x1080, png format)</label>
                        <input id="image"  onChange={handleFileChange} className="form-control" type="file" accept="image/png, image/jpeg" required></input>
                        <div className="pt-2" style={{height: "100px"}}>
                        {imagePreview && (
                                <img
                                src={imagePreview}
                                alt="Image preview"
                                style={{height: "100%"}}
                                />
                        )}
                        </div>

                        <label>Art (för sortering)</label>
                        <div>
                            <label className="pe-3">Abborre</label>
                            <input id="artPerch" className="form-check-input" type="checkbox"></input>
                        </div>
                        <div>
                            <label className="pe-3">Gädda</label>
                            <input id="artPike" className="form-check-input" type="checkbox"></input>
                        </div>

                        <label>Optionellt (för framsidan sliders)</label>
                        <div>
                            <label className="pe-3">News slider</label>
                            <input id="newsslider" className="form-check-input" type="checkbox"></input>
                        </div>
                        <div>
                            <label className="pe-3">Deals slider</label>
                            <input id="dealslider" className="form-check-input" type="checkbox"></input>
                        </div>


                        <label className="pt-2">Vikt i gram </label>
                        <input id="weight" className="form-control" type="number" required></input>

                        <label>Längd i cm </label>
                        <input id="length" className="form-control" type="number" required></input>

                        <label>Färg </label>
                        <input id="color" className="form-control" type="text" required></input>
                        
                        <label>Lagersaldo</label>
                        <input id="lager" className="form-control" type="number" required></input>

                        <label>Beskrivning</label>
                        <textarea id="desc" style={{height: "225px", resize: "none"}} className="form-control" type="text" required/>

                        <button className="btn btn-success w-100 my-2">Ladda upp produkt</button>
                        


                    </form>
  
                </div>
            </div>

            <div className="row d-flex justify-content-center pt-3">
                <div className="col-10">
                    {currentProducts.map((item, index) => (
                        <AdminProduct key={index} {...item}></AdminProduct>
                    ))}

                </div>
            </div>

        </div>
    );

}

export default Adminpanel;