import { useEffect, useState } from "react";
import Slider from "react-slick";
import "./productslider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Productcard from "./product";
import Productinslider from "./productinslider";

function Productslider({fetchsource}) {
    
    const [sliderProducts, setSliderProducts] = useState([]);
    const [apiCallDone, setApiCallDone] = useState(false);

    useEffect(() => {
        
        
        const fetchProd = async () => {
            try {

                const response = await fetch(`https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/fetch-slider-products-${fetchsource}`);
                const data = await response.json();
                
                setSliderProducts(data.data || []);
                setApiCallDone(true);
            } catch (error) {
                console.log("Failed to fetch home products!");
            }
        };


        fetchProd();
        

    }, [fetchsource]);

    



    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Default number of items to show
        slidesToScroll: 1, // Number of items to scroll per click
        responsive: [
            {
                breakpoint: 1213,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 973,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                },
            }
        ],
    };

    return (
        <div className="product-slider">
       
       
       
        {apiCallDone ? (
            sliderProducts.length > 0 ? (
                <Slider {...settings}>
                    {sliderProducts.map((product, index) => (
                        <div key={index} className="slider-item">
                            <Productinslider product={product} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <p className="text-center fs-5">Inga kampanjer för tillfället.</p> // Render this when there are no products
            )
        ) : (
            <div className="d-flex justify-content-center w-100">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
    </div>
    );
}

export default Productslider;
